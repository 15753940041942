import { getToken } from "./TokenManage";

//export const BASE_URL = "http://165.22.244.117:8080";
export const BASE_URL = "https://thinkiot.com.bd";


export const BASE_HEADERS = () => {

  const token = getToken()

  if(token)
  {
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Content-Security-Policy': 'upgrade-insecure-requests',
      'X-Authorization': "Bearer " + token
    }
  }

  return {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Content-Security-Policy': 'upgrade-insecure-requests',
  }

 
}