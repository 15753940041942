import Moment from 'moment'

export const dateString = (value, formatValue = "h:mm A MMM D, YYYY") =>{

    // if(Moment(value).format(formatValue)!=null)
    // {
    //     return Moment(value).format(formatValue);
    // }

    return Moment(value).format(formatValue);
} 

export const formatDateValue = (value, oldFormat, newFormat) => {

   const format = "h:mm A MMM D, YYYY" 
   const date = new Date(value)
   const newFormatValue = Moment(date.getTime()).format(format)

   return newFormatValue

}

//Thu Sep 23 2021 13:03:49 GMT+0600 (BDT)