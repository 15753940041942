
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { getLabelObject } from './widgetList';
import { TextField } from '@mui/material';

const initLabels = {
  labelInput: ""
}


export default (props) => {

  const { onSave, addWidget } = props;
  const [label, setLabel] = useState(initLabels)


  const handleOnChange = (e) => {

    const name = e.target.name;
    console.log("Label field", name)
    setLabel({[name]: e.target.value});
  }

  return (
    <aside>

      <Button variant="contained" color={"primary"} onClick={() => {
        onSave()
      }
      } startIcon={<SaveIcon />}>Save</Button>

      <TextField id="standard-basic" label="Label" className="labelInput" name="labelInput" variant="standard" onChange={handleOnChange}/>
      <Button variant="contained" color={"primary"} onClick={() => {
        addWidget(getLabelObject(label.labelInput))
        setLabel()
        }}>Add Label</Button>

      
      {/* <div className="description">You can drag these nodes to the pane on the right.</div>
      <div className="dndnode input" onDragStart={(event) => onDragStart(event, 'input')} draggable>
        Input Node
      </div>
      <div className="dndnode" onDragStart={(event) => onDragStart(event, 'default')} draggable>
        Default Node
      </div>
      <div className="dndnode label" onDragStart={(event) => onDragStart(event, 'label')} draggable>
        Label
      </div> */}
    </aside>
  );
};