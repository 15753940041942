import { CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_REQUEST_FAILURE, CHANGE_PASSWORD_REQUEST_SUCCESS, INPUT_VALIDATION_ERROR } from "../utils/constants";
import { initMessageObject } from "../utils/initMessage";


const initState = {
    isLoading: false,
    error: null,
    response: {},
    success:false,
    showMessage: false,
    messageDialog: initMessageObject
  };
  
  const changePassowrdReducer = (state = initState, action) => {
    switch (action.type) {
  
  
      case INPUT_VALIDATION_ERROR: {
        return {
          ...state,
          isLoading: false,
          error: null,
          showMessage: true,
          messageDialog: action.payload
        };
      }
      case CHANGE_PASSWORD_REQUEST: {
        return {
          ...state,
          isLoading: true,
          error: null
        };
      }
  
      case CHANGE_PASSWORD_REQUEST_SUCCESS: {

        const newMessage = {...initMessageObject,
          show: true,
          title: "Change password status",
          message: "Password updated successfully",
          messageType: "success",  
         }

        return {
          ...state,
          isLoading: false,
          response: action.payload,
          success: true,
          showMessage: true,
          messageDialog: newMessage
        };
      }
      case CHANGE_PASSWORD_REQUEST_FAILURE: {

        const newMessage = {...initMessageObject,
          show: true,
          title: "Change password status",
          message: action.payload.message,
          messageType: "danger",  
         }

        return {
          ...state,
          isLoading: false,
          error: action.payload,
          success: false,
          showMessage: true,
          messageDialog: newMessage
        };
      }
  
  
  
      default:
        return state;
    }
  };

export default changePassowrdReducer
  