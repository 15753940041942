import React from 'react'
import { Route, Redirect } from 'react-router'

export default function PrivateRoute({ children, ...rest }) {
    return (
        <Route exact {...rest} render={() => {
            return localStorage.getItem('isLoggedIn')
              ? children
              : <Redirect to={`${process.env.PUBLIC_URL}/login`} />
          }} />
    )
}
