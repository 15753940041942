
const initialState = {
    isLoading: false,
    error: null,
    response: {},
    success: false
}

const deviceActivityListReducer = (state = initialState, action) => {

    switch (action.type) {
        case "FETCH_DEVICE_ACTIVITY_LIST":

            return {
                ...state,

                isLoading: true
            }
        case "FETCH_DEVICE_ACTIVITY_LIST_SUCCESS":

            return {
                ...state,

                isLoading: false,
                response: action.payload,
                success: true
            }
        case "FETCH_DEVICE_ACTIVITY_LIST_FAILURE":
            return {...state, 
                    isLoading: false,
                    error: action.payload,
                    success: false
            }    

        default:
            return state;
    }
}

export default deviceActivityListReducer