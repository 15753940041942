import { REQUEST_PROFILE_UPDATE,
  REQUEST_PROFILE_UPDATE_SUCCESS,
  REQUEST_PROFILE_UPDATE_FAILED
 } from "../utils/constants"

const initialState = {
  isLoading: false,
  showMessage: false,
  updatedMessageDialog: {},
  error: null,
  response: {},
  success:false
};

const userProfileUpdateReducer = (state = initialState, action) => {

  switch (action.type) {

    case REQUEST_PROFILE_UPDATE: {

      return {
        ...state,
        user: action.payload,
        isLoading: true
      };
    }
    case REQUEST_PROFILE_UPDATE_SUCCESS: {

      const message = {
        show: true,
        title: "Profile update status",
        message:"Profile updated successfully.",
        messageType: "success",
      }

      return {
        ...state,
        isLoading: false,
        response: action.payload,
        updatedMessageDialog: message,
        showMessage: true,
        success: true,


      }
    } 
    case REQUEST_PROFILE_UPDATE_FAILED: {

      const message = {
        show: true,
        title: "Profile update status",
        message: action.payload,
        messageType: "danger",
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        updatedMessageDialog: message,
        showMessage: true,

      }
    }

    default:
      return state;
  }
};


export default userProfileUpdateReducer;
