
export const deviceDescendants = [
    {
        id: 1,
        name: "FEEDER -2B(U1-VANGA)",
        location: "ZINJIRA",
        device_ids: ["23337f20-07e7-11ec-be37-879f9547d739", 
         "fb379db0-1b5c-11ec-be37-879f9547d739",
         "23949fd0-3b09-11ec-94f9-012cdfbec993", 
         "352abae0-3b09-11ec-94f9-012cdfbec993", 
         "40d443c0-3b09-11ec-94f9-012cdfbec993"
            
        ],
        devices: []

    }]


    export const DUMMY_PLACE_LIST = [
        {
            id: 1,
            position: [23.48, 90.895],
            title: "Device"
        },
        {
            id: 2,
            position: [23.49, 90.975],
            title: "Device"
        },
        {
            id: 3,
            position: [23.65, 90.855],
            title: "Device"
        },
        {
            id: 4,
            position: [23.35, 90.876],
            title: "Device"
        },
        {
            id: 5,
            position: [23.35, 90.845],
            title: "Device"
        }, {
            id: 6,
            position: [23.48, 90.395],
            title: "Device"
        },
        {
            id: 7,
            position: [23.49, 90.675],
            title: "Device"
        },
        {
            id: 8,
            position: [23.65, 90.765],
            title: "Device"
        },
        {
            id: 9,
            position: [23.35, 90.966],
            title: "Device"
        },
        {
            id: 10,
            position: [23.35, 90.805],
            title: "Device"
        }
    
    ]