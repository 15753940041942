import React from "react";
import { connect } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "../assets/images/icons/icon-app-think-breb-white.png";
import { userActions } from "../actions";
import { userType } from '../utils/userUtils'
import { Spinner } from "react-bootstrap";
import Message from "../utils/Message";
import { CUSTOMER_USER, TENANT_ADMIN } from "../utils/constants";


class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoad: true,
      username: '',
      password: '',
      submitted: false,
      login_success: false,
      rememberMe: localStorage.getItem('rememberMe')
    }

    this.handleOnChange = this.handleOnChange.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
  }


  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoad: false
      })
    }, 500);
    document.body.classList.remove("theme-cyan");
    document.body.classList.remove("theme-purple");
    document.body.classList.remove("theme-blue");
    document.body.classList.remove("theme-green");
    document.body.classList.remove("theme-orange");
    document.body.classList.remove("theme-blush");
  }

  componentWillUnmount(){
    this.setState = (state, callBack) =>
    {
      return; 
    }
  }

  handleOnChange(e) {

    const name = e.target.name;
    this.setState({ [name]: e.target.value });
  }

  submitLogin(e) {
    e.preventDefault();
    console.log("email and password")
    this.setState({ submitted: true });
    const { username, password } = this.state;
    this.props.login(username, password);
  }

  redirectUrl() {

    console.log(userType())

    if (userType() === TENANT_ADMIN) {
      this.props.history.push('/customer-dashboard')
    } else if (userType() === CUSTOMER_USER) {
      this.props.history.push('/dashboard')
    } else {
      this.props.history.push('/dashboard')
    }
  }

  loginError(error) {

    console.log(error)
  }



  render() {

    const { isLoading, error, email, password, login_success, showMessage, messageDialog } = this.props;

    console.log("Loading login page....")
    console.log(messageDialog)
    console.log("login status" + login_success)
    console.log("LOcal logged in status; " + localStorage.getItem('isLoggedIn'))
    return (
      <div className="background-login">
        <div className="page-loader-wrapper" style={{ display: this.state.isLoad ? 'block' : 'none' }}>
          <div className="loader">
            <div className="m-t-30"><img src={require('../assets/images/loading_icon.png')} width="48" height="48" alt="Lucid" /></div>
            <p>Please wait...</p>
          </div>
        </div>
        {
          localStorage.getItem('isLoggedIn') ? this.redirectUrl() : this.loginError(error)
        }
        <div className="background-login">
          <div className="vertical-align-wrap">
            <div className="vertical-align-middle auth-main background-login">
              <div className="auth-box ">
                <div className="top">
                  <img src={Logo} alt="Lucid" style={{ height: "auto", margin: "10px" }} />
                </div>
                <div className="card">
                  <div className="header">
                    <p className="lead">Login to your account</p>
                  </div>
                  <div className="body">
                    <div className="form-auth-small" action="index.html">
                      <div className="form-group">
                        <label className="control-label sr-only">Email</label>
                        <input
                          className="form-control"
                          id="signin-email"
                          placeholder="Email"
                          type="email"
                          value={email}
                          name="username"
                          // value="user@buddybees.co"
                          onChange={this.handleOnChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label sr-only">
                          Password
                        </label>
                        <input
                          className="form-control"
                          id="signin-password"
                          placeholder="Password"
                          name="password"
                          type="password"
                          value={password}
                          onChange={this.handleOnChange}

                        />
                      </div>
                      <div className="form-group clearfix">
                        <label className="fancy-checkbox element-center">

                          {error && <span>{error.message}</span>}
                        </label>
                      </div>
                      <div className="form-group clearfix">
                        {/* <label className="fancy-checkbox element-left">
                          <input type="checkbox" checked={this.state.rememberMe} onChange={() => this.setState({
                            rememberMe: (!this.state.rememberMe)
                          })} />
                          <span>Remember me</span>
                        </label> */}
                      </div>
                      <div className="form-group clearfix">

                     {(showMessage && ! isLoading) && <Message data={messageDialog} />}

                        
                      </div>
                      <button onClick={this.submitLogin} className="btn btn-primary btn-lg btn-block">
                        {isLoading &&
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />}
                          {isLoading ? "Loading..." : "Login"}
                        </button>
           

                      <div className="bottom">
                        <span className="helper-text m-b-10">
                          <i className="fa fa-lock"></i>{" "}
                          <a href={`${process.env.PUBLIC_URL}/forgotpassword`}
                          >
                            Forgot password?
                          </a>
                        </span>
                        <span>
                          Don't have an account? Please contact THINK-BREB

                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

  login_response: state.loginReducer.login_response,
  email: state.loginReducer.email,
  password: state.loginReducer.password,
  isLoading: state.loginReducer.isLoading,
  login_success: state.loginReducer.login_success,
  error: state.loginReducer.error,
  showMessage: state.loginReducer.showMessage,
  messageDialog: state.loginReducer.messageDialog

});

const actionCreators = {
  login: userActions.login
}

export default connect(mapStateToProps, actionCreators)(Login);
