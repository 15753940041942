import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router';
import InstumateStatusCard from '../../components/InstumateStatusCard'
import { dateString } from '../../utils/DateUtils'

export default function ErrorDeviceByArea(props) {

    const history = useHistory();
    const { feeder } = props
    console.log("ErrorDevice by are feeder", feeder)


    const redirectToDetails = (device) => {

        console.log(device);

        history.push({ pathname: "/devicedetails", state: device })

    }

    return (
        <div key={feeder.id} className="marqueeitem card-design area-design-dotted">

            <div className="card-design-error">
                <div className="error-item-1 font-size-medium">{feeder.name}</div>
                <div className="error-item-1">{feeder.location}</div>
            </div>
            <div className="list-container one-item-per-line  padding-10 margin10">
                {
                    feeder.devices.map((device) => {
                        return (<InstumateStatusCard
                            key={device.id.id}
                            deviceObject={device}
                            InstumentToggle={""}
                            FirstPeraText={""}
                            FirstPeraValue={""}
                            SecondPeraText={"Created At"}
                            SecondPeraValue={dateString(device.createdTime)}
                            openDetails={() => redirectToDetails(device)}
                        />)
                    }

                    )

                }
            </div>

        </div>
    )
}
