import React from 'react'
import { MapContainer, Marker, TileLayer, Popup, Tooltip} from "react-leaflet"
import { useHistory } from 'react-router';
import InstumateStatusCard from '../../components/InstumateStatusCard';
import { dateString } from '../../utils/DateUtils';
import * as L from "leaflet";
import { DUMMY_PLACE_LIST } from '../../utils/dataStores';
import { getDeviceStatusTrueOrFalse } from '../../utils/DeviceUtitls'


const LeafIcon = L.Icon.extend({
    options: {}
  });

  const blueIcon = new LeafIcon({
    iconUrl:
      "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|abcdef&chf=a,s,ee00FFFF"
  }),
  greenIcon = new LeafIcon({
    iconUrl:
      "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|2ecc71&chf=a,s,ee00FFFF"
  });

export default function DeviceListMap(props) {

    const history = useHistory();
    const { items } = props

    const updatedDeviceList = items.map((item, index) => {
        return {...item, ...DUMMY_PLACE_LIST[index]}
    })
    const defaultPosition = [23.48, 90.895]; // Paris position
   // const [polyLineProps, setPolyLineProps] = useState([]);
    const showPreview = (place) => {
        console.log("place clicked......")
        //swal(place)
    }

    const redirectToDetails = (device) => {

        console.log(device);

        history.push({ pathname: "/devicedetails", state: device })

    }

    // const poligonProps = () => {
    //     return updatedDeviceList.reduce((prev, curr) => {
    //         prev.push(curr.position);
    //         return prev
    //       }, [])
    // }

    return (
        <div className="map-container">
            <MapContainer
                center={defaultPosition}
                zoom={10}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* <Circle center={[23.48, 90.895]} pathOptions={fillBlueOptions} radius={20000} /> */}

                {updatedDeviceList.map((place, index) => (
                    <Marker
                        key={index}
                        icon={ getDeviceStatusTrueOrFalse(place) ? greenIcon : blueIcon}
                        position={place.position} // 👈
                        eventHandlers={{ click: () => showPreview(place) }}
                    >
                        {/* show place's title on hover the marker */}
                        <Tooltip>{place.name}</Tooltip>
                        <Popup className="popupClass">
                        <InstumateStatusCard
                            key={place.id.id}
                            deviceObject={place}
                            InstumentToggle={""}
                            FirstPeraText={""}
                            FirstPeraValue={""}
                            SecondPeraText={"Created At"}
                            SecondPeraValue={dateString(place.createdTime)}
                            openDetails={() => redirectToDetails(place)}
                        />
                    </Popup>
                    </Marker>
                ))}
              
                {/* <Polyline positions={poligonProps()} />  */}
            </MapContainer>
        </div>
    )
}
