import { LOCAL_CUSTOMER_JSON, MY_PROFILE_DETAILS_JSON } from "./constants"
import { removeAllTokens } from "./TokenManage"

export const logout = () =>
{
    removeAllTokens()
    localStorage.removeItem('isLoggedIn')
    localStorage.removeItem(MY_PROFILE_DETAILS_JSON)
    localStorage.removeItem(LOCAL_CUSTOMER_JSON)
}

export const userObj = () =>{
    return JSON.parse(localStorage.getItem(MY_PROFILE_DETAILS_JSON))
}

export const userType = () =>{

    const user = userObj()

    if(user)
    {
        return user.authority

    }else
    {
        return ""
    }
}

export const getUserFullName = () =>{

    const user = userObj()
    let fullName;

    if(!user)
    {
        return "Annonymous"
    }else{
        if( !user.firstName || user.lastName === null)
        {
            fullName = "Annonymous"
        }else if( !user.lastName || user.lastName === null)
        {
            fullName += "Annonymous"
        }else
        {
            return `${user.firstName} ${user.lastName}`
        }
    }
    return fullName
}

export const getUserId = () =>{

    const user = userObj()

    if(user)
    {
        return `${user.id.id}`

    }else
    {
        return ""
    }
}

export const getCustomerId = () =>{

    const user = userObj()

    if(user)
    {
        return `${user.customerId.id}`

    }else
    {
        return ""
    }
}

export const getTenantId = () =>{

    const user = userObj()

    if(user)
    {
        return `${user.tenantId.id}`

    }else
    {
        return ""
    }
}