import React, { useState, useRef } from 'react'
import ReactFlow, { ReactFlowProvider, MiniMap } from 'react-flow-renderer';
import PageHeader from '../../../components/PageHeader';
import './dnd.css';
import Sidebar from './Sidebar';
import {
    addEdge,
    removeElements,
    Controls,
} from 'react-flow-renderer';
import { nodeTypes } from './widgetConstant';
import { INITIAL_WIDGET_POSITION, SINGLE_PHASE_DIAGRAM_JSON, } from '../../../utils/constants';
import { saveLocalStorageData } from '../../../utils/GeneralUtils';

const flow = {
    background: 'white',
    width: '100%',
    height: 500,
};


const subStyle = { background: '#ffffff', width: 50, height:50, borderRadius: '50px', 
   textAlign: 'center',   }


let id = Math.floor(Math.random(0,1)*100)+1;
const getId = () => `dndnode_${id++}`;

export default function DeviceTreeGraph() {

    //  const [elements, setData] = useState(element)
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    let mapJson=''
    if(localStorage.getItem(SINGLE_PHASE_DIAGRAM_JSON) === null || localStorage.getItem(SINGLE_PHASE_DIAGRAM_JSON) ==='')
    { 
       const mapobject = [{ id: getId(),    sourcePosition: 'right',
       data: { label: 'Sub station' }, position: { x: 450, y: 5 }, draggable: true, style: subStyle }]
       mapJson = JSON.stringify(mapobject) 
       localStorage.setItem(SINGLE_PHASE_DIAGRAM_JSON,mapJson)
      
    }else
    { 
        mapJson = localStorage.getItem(SINGLE_PHASE_DIAGRAM_JSON);
    }
    const [elements, setElements] = useState(JSON.parse(mapJson));
      

    const onConnect = (params) => setElements((els) => addEdge(params, els));
    const onElementsRemove = (elementsToRemove) =>
      setElements((els) => removeElements(elementsToRemove, els));
  
    const onLoad = (_reactFlowInstance) =>
      setReactFlowInstance(_reactFlowInstance);
  
    const onDragOver = (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';
      
    };

    const onNodeDragStop = (event, nodeType) =>{
        console.log("onNodeDragStop: ",event, event.clientX, event.clientY);
        console.log("nodeType",nodeType);
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

        const position = reactFlowInstance.project({
            x: nodeType.position.x,
            y: nodeType.position.y,
          });

         // loop over the todos list and find the provided id.
    let updatedElement = elements.map(item => 
    {
      if (item.id === nodeType.id){
        return {...item, position: position}; //gets everything that was already in item, and updates "done"
      }
      return item; // else return unmodified item 
    });


    console.log("updatedElement",updatedElement)
      setElements(updatedElement)
    }
  
    const onDrop = (event) => {
      event.preventDefault();
      console.log("onDrop: ", event.clientX, event.clientY);
  
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');
      const position = reactFlowInstance.project({
        x: event.layerX ,
        y: event.layerY ,
      });
      const newNode = 
      { id: getId(), data: { label: '15/15/15', device: {name: 'Device 10'} },  sourcePosition: 'left', targetPosition: 'right', position: position, draggable: true, style: subStyle }
      
      setElements((es) => es.concat(newNode));
    };

    const saveGraphData = async () => {

        const widgetsJSONData = JSON.stringify(elements)
        saveLocalStorageData(SINGLE_PHASE_DIAGRAM_JSON, widgetsJSONData)
    }

    const addWidget = (nodeObject) => {

      const position = reactFlowInstance.project({
        x: INITIAL_WIDGET_POSITION,
        y: INITIAL_WIDGET_POSITION,
      });

      const nodeWithposition = {...nodeObject, position: position}
      setElements((es) => es.concat(nodeWithposition));

    }

    const onElementClick = (event, element) => {
      console.log("event",event);
      console.log("element", element);
    }

    return (
        <div className="device-container">
            
            <PageHeader
                HeaderText="Device tree graph"
                Breadcrumb={[
                    { name: "Device graph", navigate: "" },

                ]}
            />
            <div className="dndflow">
                <ReactFlowProvider>
                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                        <ReactFlow
                            minZoom={1}
                            maxZoom={1}
                            style={flow}
                            elements={elements}
                            onConnect={onConnect}
                            onElementClick={onElementClick}
                            onElementsRemove={onElementsRemove}
                            nodeTypes={nodeTypes}
                            onLoad={onLoad}
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                            onNodeDragStop ={onNodeDragStop}
                            preventScrolling ={true}
                            >
                            <Controls />
                            {/* <MiniMap /> */}
                        </ReactFlow>
                    </div>
                    <div>
                    
                    
                    </div>
                    <Sidebar onSave={saveGraphData} addWidget={addWidget} />
                </ReactFlowProvider>


            </div>
        </div>

    )
}
