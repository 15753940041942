import { DeviceListType } from "./constants"
import { getDeviceActiveStatus } from "./GeneralUtils"
import ACTIVE_DEVICE_ICON from "../assets/images/icons/icon-green-glow.png"
import INACTIVE_DEVICE_ICON from "../assets/images/icons/icon-gray-glow.png"



export const deviceListWithDeviceStatus = (deviceList) => {

    return deviceList.map(device => {
        return { ...device, status: getDeviceActiveStatus(device.attributes) }
    })

}

export const getDeviceStatusTrueOrFalse = (deviceObj) =>{

    // console.log("Device status get called")

    if(deviceObj)
    {
      if(deviceObj.statusInfo)
      {
        if(deviceObj.statusInfo.s ==='1')
        {
          return true
        }else{
         return false
        }

      }else
      {
       return false
      }

    }else{
      return false
    }

}

export const deviceStatusOnly = (deviceList, deviceName) => {

    if(deviceList === null) return null

    const filteredList = deviceList.filter(device => { return device.i === deviceName})

    if(filteredList === null || filteredList.length === 0) return null
    
    return filteredList[0]

}

export const getDevicesByConnectedTypes = (deviceListWithStatus, deviceType) => {

    let fiteredDeviceList

    if (deviceType === DeviceListType.CONNECTED) {
        fiteredDeviceList = deviceListWithStatus.filter(device => {
            return device.status === true
        })

    } else if (deviceType === DeviceListType.DISCONNECTED) {
        fiteredDeviceList = deviceListWithStatus.filter(device => {
            return device.status === false
        })

    } else if (deviceType === DeviceListType.UNTRACKED) {
        fiteredDeviceList = deviceListWithStatus.filter(device => {

            if (!device.status) {
                return true
            }
            return false
        })

    } else {
        fiteredDeviceList = deviceListWithStatus
    }

    return fiteredDeviceList

}