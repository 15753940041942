import { BASE_HEADERS } from "../utils/baseValues";

import axios from 'axios'
import { getLoginURL, getChangePasswordURL, getOwnUserDetailsURL, getUserLogoutURL } from "../utils/allurls";
import { CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_REQUEST_FAILURE, CHANGE_PASSWORD_REQUEST_SUCCESS, INPUT_VALIDATION_ERROR, INVALID_INPUT, LOGIN_REQUEST, LOGIN_REQUEST_FAILURE, LOGIN_REQUEST_SUCCESS, MY_PROFILE_DETAILS_JSON, USER_LOGOUT_REQUEST, USER_LOGOUT_REQUEST_FAILURE, USER_LOGOUT_REQUEST_SUCCESS } from "../utils/constants";
import { initMessageObject } from "../utils/initMessage";
import { setRefreshToken, setToken } from "../utils/TokenManage";

const login = (userid, password) => async (dispatch, getState) => {



   if (!userid) {

      dispatch({ type: INVALID_INPUT, payload: "Email field is blank!" })
      return
   } else if (!password) {
      dispatch({ type: INVALID_INPUT, payload: "Password field is blank!" })
      return
   }

   dispatch({ type: LOGIN_REQUEST })

   try {

      const inputBody = {
         username: userid,
         password: password
      }

      const headers = BASE_HEADERS()
      const response = await axios.post(getLoginURL, inputBody, { headers });

      if (response.status === 200) {

         setToken(response.data.token)
         setRefreshToken(response.data.refreshToken)
         localStorage.setItem('isLoggedIn', true)

         const headers = BASE_HEADERS()


         const userResponse = await axios.get(getOwnUserDetailsURL, { headers });

         if (userResponse.status === 200) {

            const userJSON = JSON.stringify(userResponse.data)
            localStorage.setItem(MY_PROFILE_DETAILS_JSON, userJSON)

         }

         dispatch({ type: LOGIN_REQUEST_SUCCESS, payload: userResponse.data })

      } else {
         dispatch({ type: LOGIN_REQUEST_FAILURE, payload: response.data.message })
      }

   } catch (error) {

      dispatch({ type: LOGIN_REQUEST_FAILURE, payload: error.response.data.message })

   }

}

const changePassword = (currentPassword, newPassword, confirmPassword) => async (dispatch, getState) => {

   var errorMessage = {

   }

   if (!currentPassword) {

      errorMessage = {
         ...initMessageObject,
         show: true,
         title: "Input validation status",
         message: "Current password field is blank!",
         messageType: "danger"
      }
   } else if (!newPassword) {

      errorMessage = {
         ...initMessageObject,
         show: true,
         title: "Input validation status",
         message: "New password field is blank",
         messageType: "danger"
      }

   } else if (!confirmPassword) {
      errorMessage = {
         ...initMessageObject,
         show: true,
         title: "Input validation status",
         message: "Confirm password field is blank!",
         messageType: "danger"
      }

   } else if (newPassword !== confirmPassword) {
      errorMessage = {
         ...initMessageObject,
         show: true,
         title: "Input validation status",
         message: "New password and confirm password not matched!",
         messageType: "danger"
      }

   }

   if (errorMessage.show) {
      dispatch({ type: INPUT_VALIDATION_ERROR, payload: errorMessage })
      return
   }

   dispatch({ type: CHANGE_PASSWORD_REQUEST })

   try {

      const inputBody = {
         currentPassword: currentPassword,
         newPassword: newPassword
      }

      const headers = BASE_HEADERS()

      const response = await axios.post(getChangePasswordURL, inputBody, { headers });

      if (response.status === 200) {

         dispatch({ type: CHANGE_PASSWORD_REQUEST_SUCCESS, payload: response.data })

      } else {
         dispatch({ type: CHANGE_PASSWORD_REQUEST_FAILURE, payload: response.error })
      }



   } catch (error) {

      dispatch({ type: CHANGE_PASSWORD_REQUEST_FAILURE, payload: error })


   }

}

export const userLogout = () => async (dispatch, getState) => {

   dispatch({ type: USER_LOGOUT_REQUEST })


   try {

      const inputData = {

      }

      const headers = BASE_HEADERS()
      const response = await axios.post(getUserLogoutURL, inputData, { headers });

      if (response.status === 200) {
         console.log("logout success...")
         dispatch({ type: USER_LOGOUT_REQUEST_SUCCESS, payload: response.data })

      } else {
         dispatch({ type: USER_LOGOUT_REQUEST_FAILURE, payload: response.error })
      }



   } catch (error) {

      console.log("error")
      console.log(error)
      dispatch({ type: USER_LOGOUT_REQUEST_FAILURE, payload: error.message.data.message })

   }

}

export const userActions = {
   login,
   changePassword
}
