import { USER_LOGOUT_REQUEST, USER_LOGOUT_REQUEST_FAILURE, USER_LOGOUT_REQUEST_SUCCESS } from "../utils/constants";

const initialState = {
  isLoading: false,
  error: null,
  response: {},
  success:false
};

const userLogoutReducer = (state = initialState, action) => {
  switch (action.type) {


    case USER_LOGOUT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case USER_LOGOUT_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        response: action.payload,
        success: true
      };
    }
    case USER_LOGOUT_REQUEST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        success: false
      };
    }

    default:
      return state;
  }
};


export default userLogoutReducer;
