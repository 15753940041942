import { GET_USER_REQUEST, GET_USER_REQUEST_SUCCESS, LOGIN_REQUEST_FAILURE } from "../utils/constants";

const initialState = {
  isLoading: false,
  error: null,
  user_response: {},
  user_success:false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {


    case GET_USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case GET_USER_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        user_response: action.payload,
        user_success: true
      };
    }
    case LOGIN_REQUEST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        user_success: false
      };
    }



    default:
      return state;
  }
};


export default userReducer;
