import React, { useEffect } from 'react'
import { dateString } from '../../utils/DateUtils';
import { useDispatch, useSelector } from "react-redux"
import { userActions } from "../../actions/AlarmActions"
import { getUserId } from '../../utils/userUtils';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
 import Button from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
 import PageHeader from "../../components/PageHeader";
// import { getUserId } from '../../utils/userUtils';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Paper, TableRow } from '@material-ui/core';
import { Spinner } from 'react-bootstrap';
import { AlarmType } from '../../utils/constants';
import swal from 'sweetalert';




const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#C0C0C0",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

//const type =AlarmType.ACK


export default function AlarmReports() {


  const dispatch = useDispatch()
  const alarmsReducer = useSelector(state => state.alarmsReducer)
  let currentDate = new Date();
  let oneDayAdd = new Date(currentDate.setDate(currentDate.getDate() + 1))

  const [selectedDate, setSelectedDate] = React.useState({startDate: new Date(),
  endDate: oneDayAdd });


  useEffect(() => {

    dispatch(userActions.alarms(getUserId(), selectedDate.startDate.getTime(), selectedDate.endDate.getTime()))

  }, []);


  const renderAlarmRows = (rows) => {

    if (rows) {

       if(rows.length > 0) {
        return rows.map((row) => (
          <StyledTableRow key={row.ackTs}>
            <StyledTableCell>
              {dateString(row.createdTime)}
            </StyledTableCell>
            <StyledTableCell align="right">{row.originatorName}</StyledTableCell>
            <StyledTableCell align="right">{row.type}</StyledTableCell>
            <StyledTableCell align="right">{row.severity}</StyledTableCell>
            <StyledTableCell align="right">{row.status}</StyledTableCell>
            <StyledTableCell align="right">{row.details}</StyledTableCell>
            <StyledTableCell align="right"><Button onClick={() => {
              console.log(row)
              confirmAcknowledgeAlarmBeforeAction(row)
            }} variant="contained">Ack</Button></StyledTableCell>
            <StyledTableCell align="right"><Button onClick={
              () => {
                confirmClearAlarmBeforeAction(row )
              }
            } variant="contained">Clear</Button></StyledTableCell>
          </StyledTableRow>
        ))
       }else {

        return (<TableRow><TableCell align="center" colSpan={6}>No alarms found</TableCell></TableRow>
  
        )
      }

      

    } else {

      return (<TableRow><TableCell align="center" colSpan={6}>No alarms found</TableCell></TableRow>

      )
    }
  }

  const confirmAcknowledgeAlarmBeforeAction = (alarm) => {
    swal({
      title: "Are you sure?",
      text: "You want to acknowledge the alarm",

      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {

        dispatch(userActions.acknowledgeAlarm(alarm.id, AlarmType.ACK))
        
      } 
    });
  }

  const confirmClearAlarmBeforeAction = (alarm) => {
    swal({
      title: "Are you sure?",
      text: "You want to clear the alarm",

      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {

        dispatch(userActions.acknowledgeAlarm(alarm.id, AlarmType.CLEAR))
        
      } 
    });
  }


  const handleStartDateChange = (date) => {
    setSelectedDate({...selectedDate, startDate: date});
  };

  const handleEndDateChange = (date) => {
    setSelectedDate({...selectedDate, endDate: date});
  };

  const handleSearch = () => {
    console.log("Start date", selectedDate.startDate)
    console.log("End date", selectedDate.endDate)

    dispatch(userActions.alarms(getUserId(), selectedDate.startDate.getTime(), selectedDate.endDate.getTime()))

  }

  return (

    <div>
      <PageHeader
          HeaderText="Alarms"
          Breadcrumb={[
            { name: "alarms", navigate: "" },

          ]}
        />
       

      <div className="rigthCenterContainer">

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker className="datePickerDesign" value={selectedDate.startDate} onChange={handleStartDateChange} />
      <span className="pading10"> TO </span>
      <DatePicker className="datePickerDesign" value={selectedDate.endDate} onChange={handleEndDateChange} />
      <button className="primaryButton" onClick={handleSearch}>
        { alarmsReducer.isLoading &&
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />}
                          {alarmsReducer.isLoading ? "Searching..." : "Search"}</button>
    </MuiPickersUtilsProvider>
      
      </div>

      {alarmsReducer.success && (<div>


        <TableContainer component={Paper}>
          <Table className="" aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Created time</StyledTableCell>
                <StyledTableCell align="right">Originated</StyledTableCell>
                <StyledTableCell align="right">Type</StyledTableCell>
                <StyledTableCell align="right">Severity</StyledTableCell>
                <StyledTableCell align="right">Status</StyledTableCell>
                <StyledTableCell align="right">Details</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderAlarmRows(alarmsReducer.response)}
            </TableBody>
          </Table>
        </TableContainer>


      </div>)}


    </div>

  )
}
