import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/PageHeader";
import { Activate, DEVICE_PER_PAGE, DEVICE_STATS_DATA, INIT_PAGE, MAP_SCREEN_ON, REFRESH_DEVICE_LIST_TIME } from "../../utils/constants";
import { getCustomerId } from "../../utils/userUtils";
import DeviceListComponent from "./DeviceListComponent";
import DeviceListMap from "./DeviceListMap";
import DisconnectedDeviceDashboard from "./DisconnectedDeviceDashboard";
import { deviceActions } from '../../actions/deviceActions';
import NoDeviceFound from "./NoDeviceFound";
import { deviceDescendants } from "../../utils/dataStores"
import { deviceListWithDeviceStatus } from "../../utils/DeviceUtitls";
import { getDeviceStatusTrueOrFalse } from "../../utils/DeviceUtitls";

export default function Dashbord(props) {

  //console.log("REACT_APP_APP_KEY",process.env.REACT_APP_APP_KEY);

  const [activateList, setActivateList] = useState(true);
  const [feederList, setFeederList] = useState([]);
  const [activeData, setActiveData] = useState({
    deviceCount: 0,
    connected: 0,
    disconnected: 0,
    untracked: 0
  });
  const dispatch = useDispatch();
  const { items, success, attributeSuccess, totalDeviceCount } = useSelector(state => state.deviceListReducer)

  var connectedDevice = 0
  var disconnectedDevice = 0

  if (localStorage.getItem(DEVICE_STATS_DATA) === null || localStorage.getItem(DEVICE_STATS_DATA) === '') {
    localStorage.setItem(DEVICE_STATS_DATA, JSON.stringify(activeData))
  }

  const checkDeviceIdAlreadyExistInFeeder = (deviceId, feeder) => {

    let existingDevice = false;
    for (let i = 0; i < feeder.device_ids.length; i++) {

      if (feeder.device_ids[i] === deviceId) {
        existingDevice = true;
        break;
      }
    }
    return existingDevice;

  }

  const getFeederInfoFromList = (feeders, device) => {

    return feeders.filter(feeder => {
      return checkDeviceIdAlreadyExistInFeeder(device.id.id, feeder)
    })

  }

  useEffect(() => {


    if (attributeSuccess) {

      var tempFeederList = []

      items.forEach(device => {

        const connectedResult = getDeviceStatusTrueOrFalse(device)
        if (connectedResult) {
          connectedDevice++;
        } else {
          disconnectedDevice++;

          let feederObject = getFeederInfoFromList(deviceDescendants, device)
          if (feederObject && feederObject.length > 0) {
            let feeder = feederObject[0]



            if (feeder.devices && feeder.devices.length !== 0) {
              const devices = [...feeder.devices, device]
              feeder = { ...feeder, devices: devices }

            } else {
              feeder = { ...feeder, devices: [device] }
            }
            deviceInsertOrUpdateIntoFeeder(tempFeederList, feeder, device)
          //  console.log("disconnectedDevice::::", disconnectedDevice)
          //  console.log("newFeederList: ", tempFeederList)
          }


        }

      });
      const latestActiveData = {
        ...activeData,
        connected: connectedDevice,
        disconnected: disconnectedDevice,
        deviceCount: totalDeviceCount,
        untracked: totalDeviceCount - (connectedDevice + disconnectedDevice)
      }

      setFeederList(tempFeederList)
      setActiveData(latestActiveData)
      localStorage.setItem(DEVICE_STATS_DATA, JSON.stringify(latestActiveData))

    }

  }, [attributeSuccess])

  function deviceInsertOrUpdateIntoFeeder(array, item, device) { // (1)
    const i = array.findIndex(_item => _item.id === item.id);
    if (i > -1) {
      const feeder = array[i]
      const devices = [...feeder.devices, device]

      array[i] = { ...feeder, devices: devices }; // (2)
    }
    else array.push(item);
  }


  useEffect(() => {

    

    const customerId = getCustomerId()
    console.log(customerId)
    dispatch(deviceActions.fetchDevicesAlongWithAttributesValue(customerId, DEVICE_PER_PAGE, INIT_PAGE))
    if (localStorage.getItem(MAP_SCREEN_ON)) {
      setActivateList(false)
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const customerId = getCustomerId()
     // console.log(customerId)
      dispatch(deviceActions.fetchDevicesAlongWithAttributesValue(customerId, DEVICE_PER_PAGE, INIT_PAGE))

    }, REFRESH_DEVICE_LIST_TIME);

    return () => clearInterval(intervalId);
  }, []);

  const handleMapAndList = (value) => {

    if (value === Activate.MAP) {
      setActivateList(false)
      localStorage.setItem(MAP_SCREEN_ON, true)
    } else {
      setActivateList(true)
      localStorage.removeItem(MAP_SCREEN_ON)
    }

  }

  const errorDeviceShowClass = (feederList) => {

    if (feederList.length > 0) {
      return "device-main-container-avtive-2"
    }

    return ""
  }

  const deviceMemo = useMemo(() => {

    return deviceListWithDeviceStatus(items)
    
  }, [items])

  
  const renderMapOrList = (activeList, deviceList) => {
  
    

    if (deviceMemo) {
      if (deviceMemo.length > 0) {

        if (activeList) {
          return (
            <DeviceListComponent items={deviceMemo}  />
          )
        } else {
          return (<DeviceListMap items={deviceMemo} />)
        }

      } else {
        return (<NoDeviceFound />)
      }
    } else {
      return (<NoDeviceFound />)
    }


  }

  return (
    <div className="dashboard-container">
      <PageHeader
        HeaderText="Dashboard"
        Breadcrumb={[{ name: "Dashboard" }]}
        handleMap={handleMapAndList}
        activeData={activeData}
      />
      <div className={`device-main-container ${errorDeviceShowClass(feederList)}`}>
        <div className="">
          {renderMapOrList(activateList, items)}
        </div>
        <div className="error-device-dashboard">
          {feederList.length > 0 && <DisconnectedDeviceDashboard feeders={feederList} />}
        </div>
      </div>
    </div>
  )
}


