import React, { useEffect} from "react";
import PageHeader from "../../components/PageHeader";
import { Tabs, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, } from '@mui/material';
import GreenImage from '../../assets/images/icons/icon-green-glow.png'
import GrayImage from '../../assets/images/icons/icon-gray-glow.png'
import Divider from '@mui/material/Divider';
import { getDeviceActiveStatus } from "../../utils/GeneralUtils";
import ICON_TRANSFORMER from "../../assets/images/icons/icon_transformer.png"
import DeviceAttributeComponent from "./DeviceAttributeComponent";
import { DEVICE_REFRESH_INTERVAL } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { deviceActions } from "../../actions/deviceActions"

export default function DeviceDetails() {

  const dispatch = useDispatch()
  const location = useLocation()
  //const deviceDetails = location.state
  const device  = location.state
  // const device = useSelector(state => {
  //   return state.deviceListReducer.items.filter(item => item.id.id === deviceDetails.id.id)
  // })

  useEffect(() => {

    const refreshDevice = setInterval(() => {

      dispatch(deviceActions.getDeviceAttributesByDeviceId(device))

    }, DEVICE_REFRESH_INTERVAL)

    return () => clearInterval(refreshDevice)
    
  },[])

  const getDescription = (description) => {

    if (description) {
      return description
    } else {
      return "No device description available!"
    }

  }

  return (

    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      {console.log("Details called.............")}
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText={device.name}
            Breadcrumb={[
              { name: "devicelist", navigate: "" },
              { name: device.name, navigate: "" },
            ]}
          />
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <Tabs
                    defaultActiveKey="device-dashboard"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="device-dashboard" title="Device dashboard">
                      <div className="device-details-container">
                        <div className="device-details-item">
                          <div className="details-item-1 card-design">
                            <Card >
                              <CardActionArea >
                                <div className="display-two-column">
                                  <img
                                    className="padding20"
                                    component="img"
                                    width="150"
                                    src={ICON_TRANSFORMER}
                                    alt={device.name}
                                  />
                                  <img
                                    className="padding20"
                                    component="img"
                                    width="150"
                                    src={getDeviceActiveStatus(device.attributes) ? GreenImage : GrayImage}
                                    alt="green iguana"
                                  />
                                </div>
                                <Divider />
                                <CardContent>
                                  <Typography gutterBottom variant="h5" component="div">
                                    {device.name}
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary">
                                    {device.label}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>

                            </Card>

                          </div>

                        </div>
                        <div className="device-details-item">
                          <Card >
                            <CardActionArea >

                              <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                  <div>Device ID{device.randomValue}:</div>
                                  <div>{device.id.id}</div>

                                </Typography>
                                <Divider />
                                <Typography gutterBottom variant="h5" component="div">
                                  Device Type:<br></br>
                                  {device.type}
                                </Typography>
                                <Divider />
                                <Typography variant="body2" color="text.secondary">
                                  Description<br></br>
                                  <span>{getDescription(device.additionalInfo.description)}</span>

                                </Typography>
                              </CardContent>
                            </CardActionArea>

                          </Card>
                        </div>
                        <div className="device-details-item-2">
                          <DeviceAttributeComponent device={device} />
                        </div>


                      </div>

                    </Tab>



                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
