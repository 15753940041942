import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper, TableRow, withStyles } from "@material-ui/core";
import { getAttributesValueByKey } from '../../utils/GeneralUtils';
import { ACTIVE, CONNECT_TIME, DISCONNECT_TIME, LAST_SERVICE_TIME, STATUS } from '../../utils/constants';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#C0C0C0",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

export default function DeviceAttributeComponent(props) {

    const { device } = props;

    return (
        <div>
            <div >

                <div className="marqueeitem">
                    <div className="marqueeitem-first">Connect time</div>
                    <div className="marqueeitem-second">{getAttributesValueByKey(device.attributes, CONNECT_TIME)}</div>
                </div>
                <div className="marqueeitem">
                    <div>Disconnect time</div>
                    <div>{getAttributesValueByKey(device.attributes, DISCONNECT_TIME)}</div>

                </div>
                <div className="marqueeitem">
                    <div>Status</div>
                    <div>{getAttributesValueByKey(device.attributes, STATUS)}</div>
                </div>
                {/* <div className="marqueeitem">
                    <div >Active</div>
                    <div>{getAttributesValueByKey(device.attributes, ACTIVE)}</div>
                </div> */}
                <div className="marqueeitem">
                    <div>Last service time</div>
                    <div>{getAttributesValueByKey(device.attributes, LAST_SERVICE_TIME)}</div>
                </div>


            </div>

        </div>
    )
}
