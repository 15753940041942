const initialState = {
  isLoading: false,
  error: null,
  response: {},
  success:false
};

const deviceAttributeReducer = (state = initialState, action) => {
  switch (action.type) {


    case "GET_DEVICE_ATTRIBUTES_REQUEST": {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case "GET_DEVICE_ATTRIBUTES_REQUEST_SUCCESS": {
      return {
        ...state,
        isLoading: false,
        response: action.payload,
        success: true
      };
    }
    case "GET_DEVICE_ATTRIBUTES_REQUEST_FAILURE": {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        success: false
      };
    }



    default:
      return state;
  }
};


export default deviceAttributeReducer;
