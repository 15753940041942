import React from 'react'
import ErrorDeviceByArea from './ErrorDeviceByArea'

export default function DisconnectedDeviceDashboard(props) {

    const { feeders } = props;
    console.log("disconnect component called:")
    console.log("disconnect devices:",feeders)


    return (
        <div className="error-device-dashboard">
            <div className="disconnected-header">Disconnected devices</div>
            <div className="error-device-dashboard">

               { feeders.map(feeder => {
                   return (<ErrorDeviceByArea key={feeder.id} feeder={feeder} />)
               })} 

            </div>
        </div>
    )
}
