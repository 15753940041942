
export const getToken = () => localStorage.getItem('token')
export const getRefreshToken = () => localStorage.getItem('refreshToken')
export const setToken = (token) => localStorage.setItem('token', token)
export const setRefreshToken = (refreshToken) => localStorage.setItem('refreshToken', refreshToken)
export const clearToken = () => localStorage.removeItem('token')
export const clearRefreshToken = () => localStorage.removeItem('refreshToken')
export const removeAllTokens = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
}