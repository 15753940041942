import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import deviceListReducer from "./deviceListReducer";
import forgetPasswordReducer from "./forgotPasswordReducer";
import userReducer from "./userReducer";
import changePassowrdReducer from "./chanagePasswordReducer";
import deviceAttributeReducer from "./deviceAttributesReducer"
import deviceActivityListReducer from "./deviceActivityListReducer";
import userLogoutReducer from "./userLogoutReducer";
import userProfileUpdateReducer from "./userProfileUpdateReducer"
import alarmsReducer from "./alarmsReducer";
import navigationReducer from "./navigationReducer";


export default combineReducers({
  loginReducer: loginReducer,
  forgetPasswordReducer: forgetPasswordReducer,
  userReducer: userReducer,
  userProfileUpdate: userProfileUpdateReducer,
  deviceActivityListReducer: deviceActivityListReducer,
  deviceAttributeReducer: deviceAttributeReducer,
  alarmsReducer: alarmsReducer,
  navigationReducer: navigationReducer,
  logout: userLogoutReducer,
  changePassowrdReducer: changePassowrdReducer,
  deviceListReducer: deviceListReducer
});
