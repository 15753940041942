export const REQUEST_PROFILE_UPDATE = "REQUEST_PROFILE_UPDATE"
export const REQUEST_PROFILE_UPDATE_SUCCESS = "REQUEST_PROFILE_UPDATE_SUCCESS"
export const REQUEST_PROFILE_UPDATE_FAILED = "REQUEST_PROFILE_UPDATE_FAILED"

export const INPUT_VALIDATION_ERROR = "INPUT_VALIDATION_ERROR"

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_REQUEST_SUCCESS = "CHANGE_PASSWORD_REQUEST_SUCCESS"
export const CHANGE_PASSWORD_REQUEST_FAILURE = "CHANGE_PASSWORD_REQUEST_FAILURE"

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST"
export const USER_LOGOUT_REQUEST_SUCCESS = "USER_LOGOUT_REQUEST_SUCCESS"
export const USER_LOGOUT_REQUEST_FAILURE = "USER_LOGOUT_REQUEST_FAILURE"

export const GET_USER_REQUEST = "GET_USER_REQUEST"
export const GET_USER_REQUEST_SUCCESS = "GET_USER_REQUEST_SUCCESS"
export const GET_USER_REQUEST_FAILURE = "GET_USER_REQUEST_FAILURE"

export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const INVALID_INPUT = "INVALID_INPUT"
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS"
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE"

export const FETCH_USER_LIST = "FETCH_USER_LIST"
export const FETCH_USER_LIST_SUCCESS = "FETCH_USER_LIST_SUCCESS"
export const FETCH_USER_LIST_FAILURE = "FETCH_USER_LIST_FAILURE"

export const INIT_CREATE_USER = "INIT_CREATE_USER"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE"

export const INIT_UPDATE_USER = "INIT_UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE"

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_REQUEST_SUCCESS = "FORGOT_PASSWORD_REQUEST_SUCCESS"
export const FORGOT_PASSWORD_REQUEST_FAILURE = "FORGOT_PASSWORD_REQUEST_FAILURE"

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE"
export const CLEAN_DELETE_FLAG = "CLEAN_DELETE_FLAG"
export const CLEAN_CREATE_USER_DATA = "CLEAN_CREATE_USER_DATA"

export const INIT_DEVICE_ASSIGN = "INIT_DEVICE_ASSIGN"
export const DEVICE_ASSIGN_SUCCESS = "DEVICE_ASSIGN_SUCCESS"
export const DEVICE_ASSIGN_FAILURE = "DEVICE_ASSIGN_FAILURE"

export const INIT_DEVICE_UNASSIGN = "INIT_DEVICE_UNASSIGN"
export const DEVICE_UNASSIGN_SUCCESS = "DEVICE_UNASSIGN_SUCCESS"
export const DEVICE_UNASSIGN_FAILURE = "DEVICE_UNASSIGN_FAILURE"
export const CLEAN_DEVICE_ASSIGN = "CLEAN_DEVICE_ASSIGN"
export const CLEAN_DEVICE_UNASSIGN = "CLEAN_DEVICE_UNASSIGN"

export const INIT_DELETE_DEVICE = "INIT_DELETE_DEVICE"
export const DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS"
export const DELETE_DEVICE_FAILURE = "DELETE_DEVICE_FAILURE"
export const CLEAN_DELETE_DEVICE = "CLEAN_DELETE_DEVICE"

export const INIT_UNASSIGN_DEVICE = "INIT_UNASSIGN_DEVICE"
export const UNASSIGN_DEVICE_SUCCESS = "UNASSIGN_DEVICE_SUCCESS"
export const UNASSIGN_DEVICE_FAILURE = "UNASSIGN_DEVICE_FAILURE"
export const CLEAN_UNASSIGN_DEVICE = "CLEAN_UNASSIGN_DEVICE"

export const INIT_CREATE_DEVICE = "INIT_CREATE_DEVICE"
export const CREATE_DEVICE_SUCCESS = "CREATE_DEVICE_SUCCESS"
export const CREATE_DEVICE_FAILURE = "CREATE_DEVICE_FAILURE"
export const CLEAN_CREATE_DEVICE = "CLEAN_CREATE_DEVICE"

export const INIT_FETCH_ALARM = "INIT_FETCH_ALARM"
export const FETCH_ALARM_SUCCESS = "FETCH_ALARM_SUCCESS"
export const FETCH_ALARM_FAILURE = "FETCH_ALARM_FAILURE"

export const INIT_ACKNOWLEDGE_ALARM = "INIT_ACKNOWLEDGE_ALARM"
export const ACKNOWLEDGE_ALARM_SUCCESS = "ACKNOWLEDGE_ALARM_SUCCESS"
export const ACKNOWLEDGE_ALARM_FAILURE = "ACKNOWLEDGE_ALARM_FAILURE"

export const INIT_CLEAR_ALARM = "INIT_CLEAR_ALARM"
export const CLEAR_ALARM_SUCCESS = "CLEAR_ALARM_SUCCESS"
export const CLEAR_ALARM_FAILURE = "CLEAR_ALARM_FAILURE"

export const AlarmType = {
    CLEAR: "ALARM_CLEAR",
    ACK: "ALARM_ACK"
}


export const CUSTOMER_USER = "CUSTOMER_USER"
export const USER = "USER"
export const TENANT_ADMIN = "TENANT_ADMIN"

export const REPORT_REFRESH_TIME_INTERVAL = 30000
export const A_DAY_IN_SENCONDS = 24*60*60

export const MY_PROFILE_DETAILS_JSON = "MY_PROFILE_DETAILS_JSON"
export const LOCAL_CUSTOMER_JSON = "LOCAL_CUSTOMER_JSON"
export const USER_INPUT_VALIDATION = "USER_INPUT_VALIDATION"

export const DISPLAY_ACTIVATION_LINK = "DISPLAY_ACTIVATION_LINK"
export const SEND_ACTIVATION_MAIL = "SEND_ACTIVATION_MAIL"
export const DISPLAY_ACTIVATION_LINK_WINDOW = "DISPLAY_ACTIVATION_LINK_WINDOW"
export const INIT_DELETE_USER = "INIT_DELETE_USER"
export const CLEAN_DELETE_USER_ACTION = "CLEAN_DELETE_USER_ACTION"
export const DISABLED_USER_ACCOUNT_SUCCESS = "DISABLED_USER_ACCOUNT_SUCCESS"
export const INIT_DISABLED_USER_ACCOUNT = "INIT_DISABLED_USER_ACCOUNT"
export const DISABLED_USER_ACCOUNT_FAILURE = "DISABLED_USER_ACCOUNT_FAILURE"
export const CLEAN_DISABLED_USER_ACCOUNT = "CLEAN_DISABLED_USER_ACCOUNT"

export const INIT_SEND_ACTIVATION_MAIL = "INIT_SEND_ACTIVATION_MAIL"
export const SEND_ACTIVATION_MAIL_SUCCESS = "SEND_ACTIVATION_MAIL_SUCCESS"
export const SEND_ACTIVATION_MAIL_FAILURE = "SEND_ACTIVATION_MAIL_FAILURE"
export const CLEAN_SEND_ACTIVATION_MAIL = "CLEAN_SEND_ACTIVATION_MAIL"
export const REMOVE_ITEM_FROM_DEVICE_LIST = "REMOVE_ITEM_FROM_DEVICE_LIST"

export const VALIDATE_INPUT_DEVICE = "VALIDATE_INPUT_DEVICE"
export const INIT_UPDATE_DEVICE = "INIT_UPDATE_DEVICE"
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS"
export const UPDATE_DEVICE_FAILURE = "UPDATE_DEVICE_FAILURE"
export const CLEAN_UPDATE_DEVICE = "CLEAN_UPDATE_DEVICE"
export const MAKE_CHANGES_TO_DEVICE_LIST = "MAKE_CHANGES_TO_DEVICE_LIST"

export const FETCH_DEVICE_LIST = "FETCH_DEVICE_LIST"
export const FETCH_DEVICE_LIST_SUCCESS = "FETCH_DEVICE_LIST_SUCCESS"
export const FETCH_DEVICE_LIST_FAILURE = "FETCH_DEVICE_LIST_FAILURE"

export const INIT_GET_JSON_DATA = "INIT_GET_JSON_DATA"
export const FETCH_SAVE_JSON_DATA_SUCCESS = "FETCH_SAVE_JSON_DATA_SUCCESS"
export const FETCH_SAVE_JSON_DATA_FAILURE = "FETCH_SAVE_JSON_DATA_FAILURE"

export const INIT_SAVE_JSON_DATA = "INIT_SAVE_JSON_DATA"
export const SAVE_JSON_DATA_SUCCESS = "SAVE_JSON_DATA_SUCCESS"
export const SAVE_JSON_DATA_FAILURE = "SAVE_JSON_DATA_FAILURE"

export const DEVICE_PER_PAGE = 10
export const INIT_PAGE = 0
export const REFRESH_DEVICE_LIST_TIME = 10000 // refresh page in every 1 minutes

export const FETCH_DEVICE_ATTRIBUTE_SUCCESS = "FETCH_DEVICE_ATTRIBUTE_SUCCESS"
export const CONNECT_TIME = "ConnectTime"
export const DISCONNECT_TIME = "DisconnectTime"
export const STATUS = "Status"
export const ACTIVE = "Active"
export const LAST_SERVICE_TIME = "Last_service_time"
export const DEVICE_ACTIVE_INDICATOR = "DEVICE_ACTIVE_INDICATOR"
export const CONNECTED = "Connected"
export const DEVICE_STATS_DATA = "DEVICE_STATS_DATA"

export const DeviceStatus = {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    DANGE: "DANGER"
}
export const DEVICE_REFRESH_INTERVAL = 10000

export const Activate = {
    LIST: 1,
    MAP: 2
}

export const MAP_SCREEN_ON = "MAP_SCREEN_ON"
export const INITIAL_WIDGET_POSITION = 250
export const SINGLE_PHASE_DIAGRAM_JSON = "SINGLE_PHASE_DIAGRAM_JSON"
export const DeviceListType = {
    CONNECTED: "CONNECTED",
    DISCONNECTED: "DISCONNECTED",
    UNTRACKED: "UNTRACKED",
    ALL: "ALL",
}
export const SELECTED_DEVICE_TYPE = "SELECTED_DEVICE_TYPE"




























