import axios from "axios";
import { BASE_HEADERS } from "../utils/baseValues";
import { acknowledgeOrClearAlarm, getAlarmsByCustomerId } from "../utils/allurls";
import { ACKNOWLEDGE_ALARM_FAILURE, ACKNOWLEDGE_ALARM_SUCCESS, FETCH_ALARM_FAILURE, FETCH_ALARM_SUCCESS, INIT_ACKNOWLEDGE_ALARM, INIT_FETCH_ALARM, INIT_CLEAR_ALARM, CLEAR_ALARM_SUCCESS, CLEAR_ALARM_FAILURE } from "../utils/constants";

const alarms = (customerId, startTime, endTime) => async (dispatch) => {

   dispatch({ type: INIT_FETCH_ALARM })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.get(getAlarmsByCustomerId(customerId,startTime, endTime), { headers });

      if (response.status === 200) {
         dispatch({ type: FETCH_ALARM_SUCCESS, payload: response.data.data })
      } else {
         dispatch({ type: FETCH_ALARM_FAILURE, payload: response.error })
      }



   } catch (error) {

      dispatch({ type: FETCH_ALARM_FAILURE, payload: error.response.data });


   }

}

const acknowledgeAlarm = (alarmId, type) => async (dispatch) => {

   dispatch({ type: INIT_ACKNOWLEDGE_ALARM })

   try {

      const body = {

      }

      const headers = BASE_HEADERS()
      const response = await axios.post(acknowledgeOrClearAlarm(alarmId, type), body, { headers });

      if (response.status === 200) {
         dispatch({ type: ACKNOWLEDGE_ALARM_SUCCESS, payload: response.data.data })
      } else {
         dispatch({ type: ACKNOWLEDGE_ALARM_FAILURE, payload: response.error })
      }



   } catch (error) {

      dispatch({ type: ACKNOWLEDGE_ALARM_FAILURE, payload: error.response.data });


   }

}

const clearAlarm = (alarmId, type) => async (dispatch) => {

   dispatch({ type: INIT_CLEAR_ALARM })

   try {

      const body = {

      }

      const headers = BASE_HEADERS()
      const response = await axios.post(acknowledgeOrClearAlarm(alarmId, type), body, { headers });

      if (response.status === 200) {
         dispatch({ type: CLEAR_ALARM_SUCCESS, payload: response.data.data })
      } else {
         dispatch({ type: CLEAR_ALARM_FAILURE, payload: response.error })
      }



   } catch (error) {

      dispatch({ type: CLEAR_ALARM_FAILURE, payload: error.response.data });


   }

}

export const userActions = {
   alarms,
   acknowledgeAlarm,
   clearAlarm
}