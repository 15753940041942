import { FETCH_DEVICE_ATTRIBUTE_SUCCESS, FETCH_DEVICE_LIST, FETCH_DEVICE_LIST_FAILURE, FETCH_DEVICE_LIST_SUCCESS, MAKE_CHANGES_TO_DEVICE_LIST, REMOVE_ITEM_FROM_DEVICE_LIST } from "../utils/constants"

const initialState = {
    isLoading: false,
    error: null,
    items: [],
    success: false,
    attributeSuccess: false,
    totalDeviceCount: 0, 
}

const deviceListReducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_DEVICE_LIST:

            return {
                ...state,

                isLoading: true
            }
        case FETCH_DEVICE_LIST_SUCCESS:

        const deviceCount = action.payload ? action.payload.length : 0

            return {
                ...state,

                isLoading: false,
                items: action.payload,
                success: true,
                totalDeviceCount: deviceCount
            }

        case FETCH_DEVICE_ATTRIBUTE_SUCCESS:

    
                return {
                    ...state,
                    isLoading: false,
                    items: action.payload,
                    attributeSuccess: true
                }
        case MAKE_CHANGES_TO_DEVICE_LIST:

            const updatedDevice = action.payload

            const updatedDevices = state.items.map((device) => {
                if(updatedDevice.id.id === device.id.id)
                {
                    return updatedDevice
                }
                return device
            })

                return {
                    ...state,
    
                    isLoading: false,
                    items: updatedDevices,
                }    
            
        case REMOVE_ITEM_FROM_DEVICE_LIST:

            const newDeviceList = state.items.filter(item => {

                return !(item.id.id === action.payload.id.id)

            })

            return {
                ...state,

                isLoading: false,
                items: newDeviceList,
            }
        case FETCH_DEVICE_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                success: false,
                attributeSuccess: false
            }

        default:
            return state;
    }
}

export default deviceListReducer