import swal from "sweetalert"
import { STATUS, TENANT_ADMIN, ACTIVE, LAST_SERVICE_TIME, DeviceStatus, CONNECTED } from "./constants"
import { dateString, formatDateValue } from "./DateUtils"
import { userType } from "./userUtils"

export const getDeviceActiveStatus = (attributes) => {

  if (!attributes) {
    return false
  } else {
    const findAttribute = attributes.find(attribute => {
      return attribute.key === STATUS
    })

    //console.log("findattributes status", findAttribute)
    
    if (findAttribute) {
      if(findAttribute.value === 1)
      {
        return true
      }else
      {
        return false
      }

    } else {
      return false
    }

  }
}

export const transportType = [
  {
    id: 1,
    name: "default"
  },
  {
    id: 2,
    name: "MQTT"
  }
]

export const keyToTitle = (key) => {

  if (key === 'GeneratorFrequency') {
    return "Generator Frequency"
  } else if (key === 'BatteryVoltage') {
    return "Battery Voltage"
  } else if (key === 'OutputVoltage') {
    return "Output Voltage"
  } else if (key === 'lastConnectTime') {
    return "Last Connect Time"
  } else if (key === 'lastDisconnectTime') {
    return "Last Disconnect Time"
  } else if (key === 'inactivityAlarmTime') {
    return "Inactivity Alarm Time"
  } else if (key === 'lastActivityTime') {
    return "Last Activity Time"
  }

  return key;
}

export const valueToFormattedValue = (item) => {

  if (item.label1 === 'lastActivityTime' || item.label1 === 'inactivityAlarmTime' || item.label1 === 'lastDisconnectTime' || item.label1 === 'lastConnectTime') {
    return dateString(item.label2)
  } else if (item.label1 === 'Temperature') {
    return `${item.label2} C`
  } else if (item.label1 === 'Humidity') {
    return item.label2 + "%"
  } else if (item.label1 === 'OutputVoltage') {
    return item.label2 + "V"
  } else if (item.label1 === 'GeneratorFrequency') {
    return item.label2 + ""
  } else if (item.label1 === 'Fuel') {
    return item.label2 + "%"
  } else if (item.label1 === 'Humidity') {
    return item.label2 + "%"
  } else if (item.label1 === 'Pressure') {
    return item.label2 + " pascal"
  }

  return item.label2
}

export const getUserEnabledStatus = (user) => {

  const { additionalInfo } = user

  if (additionalInfo.hasOwnProperty('userCredentialsEnabled')) {
    return additionalInfo.userCredentialsEnabled
  } else {
    return true
  }
}

export const dashboardURLBasedOnUserType = () => {

  if (userType() === TENANT_ADMIN) {
    return "customer-dashboard"
  } else {
    return "dashboard"
  }
}

export const showCustomMessage = (message, icon = "warning") => {

  swal(message, {
    icon: icon,
  })

}

export const getAttributesValueByKey = (attributes, key) => {

  if (!attributes) {
    return ""
  } else {
    const findAttribute = attributes.find(attribute => {
      return attribute.key === key
    })
    
    if (findAttribute) {
      if ( findAttribute.key === LAST_SERVICE_TIME) {

        return findAttribute.value

      }else if(findAttribute.key === ACTIVE){
        
        if(findAttribute.value === 1)
        {
          return "True"
        }else
        {
          return "False"
        }
      }else if(findAttribute.key === STATUS){
        
        if(findAttribute.value === 1)
        {
          return "Connected"
        }else
        {
          return "Disconnected"
        }
      }  else {
        return formatDateValue(findAttribute.value)
      }

    } else {
      return ""
    }

  }

}

export const getUniqueId = () => {
  return 'widget-' + Date.now();
}

export const saveLocalStorageData = (itemName, data) => {
  localStorage.setItem(itemName,data);
}

export const getSavedLocalStorageData = (itemName) => {
  return localStorage.getItem(itemName);
}
