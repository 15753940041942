import React from "react";
import { getDeviceStatusTrueOrFalse } from "../utils/DeviceUtitls";
import ICON_TRANSFORMER from "../assets/images/icons/icon_transformer.png"
import ACTIVE_DEVICE_ICON from "../assets/images/icons/icon-green-glow.png"
import INACTIVE_DEVICE_ICON from "../assets/images/icons/icon-gray-glow.png"
import DeviceAttributeComponent from "../screens/Dashbord/DeviceAttributeComponent";

export default function InstumateStatusCard(props) {


  const {
    deviceObject, openDetails } = props;

    const getDeviceStatusIcon = (device) =>{

     const status  = getDeviceStatusTrueOrFalse(device)

      return status ? ACTIVE_DEVICE_ICON : INACTIVE_DEVICE_ICON
    }

  return (

    <div className="list-container-item card-design-white height100" onClick={() => { openDetails(deviceObject.name) }}>
      <div className="list-left-icon-layout">
        <img width="30px" height="30px" alt="AC" src={ICON_TRANSFORMER} />
        <h6 style={{ "fontSize": "12px" }}>{deviceObject.name}</h6>
        <span>{deviceObject.label}&nbsp;</span>
      </div>
      <div>
      <marquee behavior="scroll" direction="up" scrollamount="2" height="100">
      <DeviceAttributeComponent device={deviceObject} />
        </marquee>
      </div>
      <div className="indicator-image">
        <img alt="Active" width="30px" height="30px" src={getDeviceStatusIcon(deviceObject)} />
      </div>


    </div>
  )
}
