import axios from 'axios';
import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_REQUEST_FAILURE, FORGOT_PASSWORD_REQUEST_SUCCESS, INPUT_VALIDATION_ERROR } from "../utils/constants";
import { initMessageObject } from "../utils/initMessage";
import { forgotpasswordURL } from "../utils/allurls";

const recoverPassword = (email) => async (dispatch, getState) => {

  if (!email) {
    const errorMessage = {
      ...initMessageObject,
      show: true,
      title: "Input validation status",
      message: "Email field is blank!",
      messageType: "danger"
    }

    dispatch({ type: INPUT_VALIDATION_ERROR, payload: errorMessage })
    return
  }

  dispatch({ type: FORGOT_PASSWORD_REQUEST })

  try {

    const inputBody = {
      email: email
    }

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    const response = await axios.post(forgotpasswordURL, inputBody, { headers });

    if (response.status === 200) {

      dispatch({ type: FORGOT_PASSWORD_REQUEST_SUCCESS, payload: response.data.message })

    } else {
      //console.log("else 200")
      dispatch({ type: FORGOT_PASSWORD_REQUEST_FAILURE, payload: response.data.message })
    }



  } catch (error) {

    //console.log("error receiedd with ")
    //console.log(error)
    //console.log(error.response.data)
    dispatch({ type: FORGOT_PASSWORD_REQUEST_FAILURE, payload: error.response.data })

  }

}

export const userActions = {
  recoverPassword

}
