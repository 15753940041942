import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_REQUEST_FAILURE, FORGOT_PASSWORD_REQUEST_SUCCESS, INPUT_VALIDATION_ERROR } from "../utils/constants";
import { initMessageObject } from "../utils/initMessage";

const initialState = {
  isLoading: false,
  error: null,
  message: "",
  success: false,
  email: "",
  showMessage: false,
  messageDialog: initMessageObject
};

const forgetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {


    case INPUT_VALIDATION_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: null,
        showMessage: true,
        messageDialog: action.payload
      };
    }


    case FORGOT_PASSWORD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case FORGOT_PASSWORD_REQUEST_SUCCESS: {

      const successMessageDialog ={
        ...initMessageObject,
        show: true,
        title: "Password recovery status",
        message: action.payload,
        messageType: "success"
      }

      return {
        ...state,
        isLoading: false,
        message: action.payload,
        success: true,
        showMessage: true,
        messageDialog:successMessageDialog 
      };
    }
    case FORGOT_PASSWORD_REQUEST_FAILURE: {

      const successMessageDialog ={
        ...initMessageObject,
        show: true,
        title: "Password recovery status",
        message: action.payload.message,
        messageType: "danger"
      }

      return {
        ...state,
        isLoading: false,
        error: action.payload,
        success: false,
        showMessage: true,
        messageDialog: successMessageDialog 
      
      };
    }

    default:
      return state;
  }
};

export default forgetPasswordReducer;
