import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "./screens/Login";
import NavbarMenu from "./components/NavbarMenu";
import registration from "./screens/Auth/Registration";
import lockscreen from "./screens/Auth/Lockscreen";
import forgotpassword from "./screens/Auth/ForgotPassword";
import page404 from "./screens/Auth/Page404";
import page403 from "./screens/Auth/Page403";
import page500 from "./screens/Auth/Page500";
import page503 from "./screens/Auth/Page503";
import profilev1page from "./screens/Pages/ProfileV1";
import profilev2page from "./screens/Pages/ProfileV2";
import maintanance from "./screens/Pages/Maintanance";
import ProfileDetails from "./screens/Pages/ProfileDetails";
import ChangePasswordSettings from "./components/Pages/ChangePasswordSettings";
import DeviceDetails from './screens/Dashbord/DeviceDetails'
import PrivateRoute from "./utils/PrivateRoute";
import Dashboard from "./screens/Dashbord/Dashbord";
import AlarmReports from "./screens/Dashbord/AlarmReports";
import DeviceTreeGraph from "./screens/Dashbord/graph/DeviceTreeGraph";

window.__DEV__ = true;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
    };
  }
  render() {

    var res = window.location.pathname;
    var baseUrl = process.env.PUBLIC_URL;
    //console.log('res: ' + res + " >>>> base url: " + baseUrl);
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? res : "/";
    const activeKey1 = res;
    //console.log('activeKey1: ' + activeKey1);


    return (
      <div id="wrapper">
        {activeKey1 === "" ||
          activeKey1 === "/" ||
          activeKey1 === "login" ||
          activeKey1 === "registration" ||
          activeKey1 === "lockscreen" ||
          activeKey1 === "forgotpassword" ||
          activeKey1 === "page404" ||
          activeKey1 === "page403" ||
          activeKey1 === "page500" ||
          activeKey1 === "page503" ||
          activeKey1 === "maintanance" ? (
          <Switch>
            {/* <Route exact path={`${process.env.PUBLIC_URL}`} component={Login} /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={Login}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              component={Login}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/forgotpassword`}
              component={forgotpassword}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page404`}
              component={page404}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page403`}
              component={page403}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page500`}
              component={page500}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/page503`}
              component={page503}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/registration`}
              component={registration}
            />
            <Route exact path={`registration`} component={registration} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/lockscreen`}
              component={lockscreen}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/maintanance`}
              component={maintanance}
            />
          </Switch>
        ) : (
          <Fragment>
            <NavbarMenu history={this.props.history} activeKey={activeKey1} />
            <div id="main-content">
              <Switch>

                {/* <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/dashboard`}
                    component={dashboard}
                   /> */}

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/dashboard`} >
                  <Dashboard />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/dashboard-map`} >
                  <Dashboard />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/device-tree-graph`} >
                  <DeviceTreeGraph  />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/device-alarms`}>
                    <AlarmReports />
                </PrivateRoute>

              

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/devicedetails`}
                >
                  <DeviceDetails />
                </PrivateRoute>

              
                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/profiledetails`}>
                  <ProfileDetails />
                </PrivateRoute>

                <PrivateRoute
                  path={`${process.env.PUBLIC_URL}/updatepassword`}>
                  <ChangePasswordSettings />
                </PrivateRoute>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/profilev1page`}
                  component={profilev1page}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/profilev2page`}
                  component={profilev2page}
                />

              </Switch>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ loginReducer }) => ({
  isLoggedin: loginReducer.isLoggedin,
});

export default withRouter(connect(mapStateToProps, {})(App));
