import axios from "axios";
import { BASE_URL } from "../utils/baseValues";
import { getRefreshToken, getToken, setRefreshToken, setToken } from "../utils/TokenManage";


const instance = axios.create({
  baseURL: BASE_URL+"/",
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Content-Security-Policy': 'upgrade-insecure-requests',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) {
       config.headers["X-Authorization"] = 'Bearer ' + token;  //
     // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/api/auth/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/api/auth/token", {
            refreshToken: getRefreshToken()
          });

          const { token, refreshToken } = rs.data;
          setToken(token);
          setRefreshToken(refreshToken)

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;