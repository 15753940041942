import { ACKNOWLEDGE_ALARM_FAILURE, ACKNOWLEDGE_ALARM_SUCCESS, CLEAR_ALARM_FAILURE, CLEAR_ALARM_SUCCESS, FETCH_ALARM_SUCCESS, FETCH_USER_LIST_FAILURE, INIT_ACKNOWLEDGE_ALARM, INIT_CLEAR_ALARM, INIT_FETCH_ALARM } from "../utils/constants";

const initialState = {
  isLoading: false,
  error: null,
  response: {},
  success:false,
  ack: false,
  clear: false,
};

const alarmsReducer = (state = initialState, action) => {
  switch (action.type) {


    case INIT_FETCH_ALARM: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case FETCH_ALARM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        response: action.payload,
        success: true
      };
    }
    case FETCH_USER_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        success: false
      };
    }

    case INIT_ACKNOWLEDGE_ALARM: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case ACKNOWLEDGE_ALARM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        response: action.payload,
        ack: true
      };
    }
    case ACKNOWLEDGE_ALARM_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        ack: false
      };
    }

    case INIT_CLEAR_ALARM: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case CLEAR_ALARM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        response: action.payload,
        clear: true
      };
    }
    case CLEAR_ALARM_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        clear: false
      };
    }



    default:
      return state;
  }
};


export default alarmsReducer;
