import React from 'react'
import Alert from 'react-bootstrap/Alert'
import { useState, useEffect} from 'react'

export default function Message(props) {

  var { show, title, message, messageType } = props.data
  console.log("message dialog called....")
  console.log(props.data)

  const [localShow, setShow] = useState(show)

  const hideMessage = ()=>
  {
    setShow(false)
  }

  useEffect(() => {
    
  }, [localShow]);

  useEffect(() => {

    return () =>
    {
      setShow(null)
    }
   
  }, [])
  
  return (<>

    { localShow && (<div><Alert variant={messageType} onClose={hideMessage} dismissible>
      <Alert.Heading>{title}</Alert.Heading>
      <p>
        {message}
      </p>
    </Alert></div>)}</>

  )
}
