import axios from "axios";
import instance from "../services/api"
import { BASE_HEADERS } from "../utils/baseValues";
import { getAttributesByDeviceId, getDevicesByCustomer } from "../utils/allurls";
import { FETCH_DEVICE_ATTRIBUTE_SUCCESS, FETCH_DEVICE_LIST, FETCH_DEVICE_LIST_FAILURE, FETCH_DEVICE_LIST_SUCCESS } from "../utils/constants";
import { deviceStatusOnly } from "../utils/DeviceUtitls"

const fetchDeviceList = (userId, pageSize, page) => async (dispatch) => {

   dispatch({ type: FETCH_DEVICE_LIST })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.get(getDevicesByCustomer(userId, pageSize, page), { headers });

      if (response.status === 200) {
         dispatch({ type: FETCH_DEVICE_LIST_SUCCESS, payload: response.data.data })

      } else {
         dispatch({ type: FETCH_DEVICE_LIST_FAILURE, payload: response.error })
      }

   } catch (error) {

      dispatch({ type: FETCH_DEVICE_LIST_FAILURE, payload: error.response.data.message })

   }

}

const fetchDevicesAlongWithAttributesValue = (userId, pageSize, page) => async (dispatch) => {

   dispatch({ type: FETCH_DEVICE_LIST })

   try {

      const headers = BASE_HEADERS()
      const response = await instance.get(getDevicesByCustomer(userId, pageSize, page), { headers });

      if (response.status === 200) {

         const deviceResponse = response.data
         

         const brebResponse = await axios.get("https://breb.thinkgroup.com.bd/devices.php", {  });

         console.log("brebResponse",brebResponse.data)

         const deviceList = deviceResponse.data.map((device) => {

            const updatedDevice = deviceStatusOnly(brebResponse.data, device.name) 

            return {...device, statusInfo: updatedDevice}
         })

         dispatch({ type: FETCH_DEVICE_LIST_SUCCESS, payload: deviceList })

     


         if (deviceList) {

            let updatedDeviceList = [...deviceList]

            for (let device of updatedDeviceList) {
              
               const attributeResponse = await instance.get(getAttributesByDeviceId(device.id.id), { headers });
              // console.log(device.id.id,attributeResponse.data)
               const updatedDevice = {
                  ...device,
                  attributes: attributeResponse.data
               }
               updatedDeviceList = updatedDeviceList.map(device => {
                  if(device.id.id === updatedDevice.id.id) {
                     return updatedDevice
                  }

                  return device
               }) 
            }

            dispatch({ type: FETCH_DEVICE_ATTRIBUTE_SUCCESS, payload: updatedDeviceList })

         }


      } else {
         console.log("Authentication issue: ::")
         dispatch({ type: FETCH_DEVICE_LIST_FAILURE, payload: response })
      }

   } catch (error) {

      console.log("request failure issue: ::")
      console.log(error.response.data)
      dispatch({ type: FETCH_DEVICE_LIST_FAILURE, payload: error.response.data.message })

   }

}

const getDeviceAttributesByDeviceId =  (device) => async(dispatch) =>{

   //dispatch({ type: FETCH_DEVICE_LIST })

   try {

      const headers = BASE_HEADERS()
      const response = await axios.get(getAttributesByDeviceId(device.id.id), { headers });
      const updatedDevice = {
         ...device,
         attributes: response.data,
         randomValue: Math.random()
      }
      console.log(updatedDevice)
      dispatch({ type: FETCH_DEVICE_ATTRIBUTE_SUCCESS, payload: updatedDevice })
  

   } catch (error) {

      //dispatch({ type: FETCH_DEVICE_LIST_FAILURE, payload: error.response.data.message })

   }

}

export const deviceActions = {
   fetchDeviceList,
   fetchDevicesAlongWithAttributesValue,
   getDeviceAttributesByDeviceId
}