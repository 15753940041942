import React from 'react'
import { DEVICE_STATS_DATA } from '../../utils/constants'

export default function DeviceActivationSummary(props) {

    const { deviceSelectedType } = props

    const { deviceCount, connected, disconnected, untracked} = JSON.parse(localStorage.getItem(DEVICE_STATS_DATA)) 

    return (
        <div className="row card-design align-items-center">
            <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 padding20 font-medium" 
            onClick={() => {deviceSelectedType()}} >
                  Connected ({connected})
            </div>
            <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 padding20 font-medium">
            Disconnected ({disconnected})
            </div>
            <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 padding20 font-medium">
            Untracked ({untracked})
            </div>
            <div className="col-lg-3 col-xl-3 col-md-6 col-sm-6 padding20 font-medium">
            All devices ({deviceCount})
            </div>
           
        </div>
    )
}
