import { getUniqueId } from "../../../utils/GeneralUtils";
import { LABEL_TYPE } from "./widgetConstant";
import { labelStyle } from "./widgetDesignCSS";

export const getLabelObject = (label) => {

      return {
            id: getUniqueId(),
            type: LABEL_TYPE,
            data: {
                  label: label,
                  type: LABEL_TYPE,
                 
            },
            draggable: true,
            style: labelStyle
      }
}